import React from 'react';
import axios from 'axios';
import App from 'next/app';
import Head from 'next/head';
import parse from 'html-react-parser';
import {
    initAqlrc, initPage, jwtManager, logoutUser
} from 'aqlrc';
import { Router } from '../routes';
import getAPIUrl from '../lib/getAPIUrl';
import 'lightbox-react/style.css';
import 'react-phone-input-2/lib/style.css'
import 'react-datepicker/dist/react-datepicker.css';

export default class AquilaApp extends App {
    static async getInitialProps(bundle) {
        initAqlrc();

        let pageProps = {};
        let cache = null;
        let appurl = null;
        let sitename = null;
        let demo = null;
        let langs = null;
        if (typeof window !== 'undefined') {
            cache = window.localStorage.getItem('cache');
            if (cache) {
                cache = JSON.parse(cache);
            }
        }
        if (!cache || (cache && Date.now() >= cache.date)) {
            const resConf = await axios.post(`${getAPIUrl(bundle.ctx)}v2/config`, {
                PostBody: {structure: {environment: 1}}
            });
            appurl = resConf.data.environment.appUrl;
            sitename = resConf.data.environment.siteName;
            demo = resConf.data.environment.demoMode;
            const resLangs = await axios.post(`${getAPIUrl(bundle.ctx)}v2/languages`, { PostBody: { limit: 99 } }); // Récupération des langues
            langs = resLangs.data.datas;
            if (typeof window !== 'undefined') {
                window.localStorage.setItem('cache', JSON.stringify({
                    appurl, sitename, demo, langs, date : Date.now() + 172800000
                }));
            }
        } else {
            appurl = cache.appurl;
            sitename = cache.sitename;
            demo = cache.demo;
            langs = cache.langs;
        }

        const lang = 'fr';
        if (typeof window !== 'undefined') {
            if (!window.localStorage.getItem('lang')) { window.localStorage.setItem('lang', lang); }
        }

        const routerLang = null; // NE PAS TOUCHER !
        const urlLang = ''; // NE PAS TOUCHER !

        if (bundle.Component.getInitialProps && bundle) {
            bundle.ctx.nsGlobals = {
                Router, langs, lang, routerLang, urlLang
            }; // Permet d'envoyer les globales dans les getInitialProps de chaque page
            pageProps = await bundle.Component.getInitialProps(bundle.ctx);
        }

        // La props "userRequired" est définie dans chaque page à true ou false
        // Elle permet de restreindre les pages qui nécessitent un utilisateur connecté
        // En fonction de ça on redirige ou non vers la page d'acceuil
        let user = jwtManager.getUser(bundle.ctx);
        if (pageProps.userRequired) {
            if (user) {
                try {
                    const resUser = await axios.post(`${getAPIUrl(bundle.ctx)}v2/user/${user._id}`, {
                        PostBody : {
                            structure : {
                                isActiveAccount   : 1,
                                company           : 1,
                                civility          : 1,
                                preferredLanguage : 1,
                                type              : 1,
                                addresses         : 1,
                                delivery_address  : 1,
                                billing_address   : 1
                            }
                        }
                    });
                    user = resUser.data;
                } catch (e) {
                    console.error(e);
                    user = undefined;
                }
            }
            if (!user || !Object.keys(user).length) {
                user = undefined;

                // Déconnexion
                await logoutUser(bundle.ctx);

                if (bundle.ctx.req) {
                    return bundle.ctx.res.redirect(`${pageProps.userRequired.url ? pageProps.userRequired.url : ''}`);
                }
                return Router.pushRoute(pageProps.userRequired.route ? pageProps.userRequired.route : 'home');
            }
        }

        // Récupération des données des blocs CMS header / footer + breadcrumb
        const initData = await initPage(bundle.ctx, 'fr');

        // Bloc CMS de la barre de cookie
        const cmsHead = await axios.post(`${getAPIUrl(bundle.ctx)}v2/component/ns-cms/head`, { lang });
        const cmsCookieBanner = await axios.post(`${getAPIUrl(bundle.ctx)}v2/component/ns-cms/cookie-banner`, { lang });

        // Clé API Google Maps
        const res = await axios.get(`${getAPIUrl(bundle.ctx)}pointOfSale/getKey`);
        const gMapKey = res.data.api_key_google_map;

        pageProps = {
            ...pageProps,
            ...initData,
            demo,
            cmsHead       : cmsHead.data.content,
            messageCookie : cmsCookieBanner.data.content,
            appurl,
            sitename,
            currentUrl    : bundle.ctx.asPath, // => NSMenu
            user,
            langs,
            lang,
            routerLang,
            urlLang,
            gMapKey
        };
        return { pageProps };
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    /* static async getDerivedStateFromProps() {
        const jwt = jwtManager.get();
        if (jwt) {
            axios.defaults.headers.common.Authorization = jwt;
        }
    } */

    componentDidMount = () => {
        /* Évènements levés pour Google Analytics */
        const init = new CustomEvent('init', {});
        window.dispatchEvent(init);
        let logPageView = new CustomEvent('logPageView', { detail: { url: window.location.pathname } });
        window.dispatchEvent(logPageView);

        Router.router.events.on('routeChangeComplete', (url) => {
            logPageView = new CustomEvent('logPageView', { detail: { url } });
            window.dispatchEvent(logPageView);
        });
    }

    render() {
        const { Component, pageProps } = this.props;
        return (
            <>
                <Head>
                    { pageProps.cmsHead ? parse(pageProps.cmsHead) : null }
                    <meta property="og:site_name" content={pageProps.sitename} />
                    <meta property="og:type" content="website" />
                    <meta itemProp="name" content={pageProps.sitename} />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
                    <script src={`https://maps.googleapis.com/maps/api/js?key=${pageProps.gMapKey}&libraries=places`} type="text/javascript"></script>
                    {
                        !pageProps.demo ? <meta name="robots" content="index,follow" />
                            : (
                                <>
                                    <meta name="robots" content="noindex,nofollow,noarchive" />
                                    <style>{`
                                        body::before { 
                                            content: "/!\\\\ This is a demo mode ! /!\\\\";
                                            background-color: red;
                                            color: #000;
                                            padding: 2px;
                                            width: 100%;
                                            text-align: center;
                                            position: fixed;
                                            z-index: 999;
                                            font-size: 11px;
                                        }
                                    `}</style>
                                </>
                            )
                    }
                </Head>
                <Component {...pageProps} />
            </>
        );
    }
}
